<template>
  <div>
    <!-- 关于我们的站点 -->
    <div class="affix" @click="goToLocation" v-if="isHomePage">
      <el-icon><LocationFilled /></el-icon>
    </div>
    <div class="box">
      <!-- <div style="display: flex;align-content: center;">
        <div class="icon-[mdi--customer-service] icon_box" style="margin-right: 10px;"></div>
        <div style="font-size: 20px;color: white; font-weight: bold; line-height: 40px;" class="font-sans">
          +(506) 8456-7880
        </div>
      </div> -->
      <div style="line-height: 40px; color: white;">
        © 2024 AIBO Plastic Products Co., Ltd., All Rights Reserved
      </div>
      <!-- <div>
        <div class="icon-[ic--baseline-facebook] icon_box"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { LocationFilled } from "@element-plus/icons-vue";
export default {
    name:'Footer', // 该组件的名字
    components: {
      LocationFilled, // 注册图标组件
    },
    data(){
      return{
        language:0 //0:ESPAÑOL  1:简体中文
      }
    },
    watch:{
      //监听路由的变化
      $route(){
        this.language = Number(this.$route.query.language)
      }
    },
    methods:{
      goToLocation(){
        this.$router.push('/location');
      }
    },
    computed: {
      // 通过计算属性获取当前路由是否是首页（/）
      isHomePage() {
        return this.$route.path === '/';
      }
    }
}
</script>

<style lang="scss" scoped>
.affix{
  border-radius: 50%;
  width: 80px;
  height: 80px;
  background-color: white;
  position: fixed;
  right: 50px;
  bottom: 70px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2), 
              -4px -4px 10px rgba(255, 255, 255, 0.9); /* 内外凸显效果 */
              display: flex; /* 使用 Flexbox */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  cursor: pointer; /* 鼠标指针样式 */
  .el-icon {
    font-size: 50px; /* 图标大小 */
    color: #dc2626; /* 图标颜色，可根据需求调整 */
  }
}
.box{
  background-color: #25a766; 
  width: 100%; 
  height: 50px;
  padding: 5px 20px;
  display: flex;
  align-content: center;
  // justify-content: space-between;
  justify-content: center;
  .icon_box{
    width: 40px; 
    height: 40px; 
    color: white;
  }
}
</style>