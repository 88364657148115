<template>
    <div class="font-serif">
        <Header style="width: 100%;"/>
        <div style="border-bottom: 1px solid #d4d4d4;"></div>
        <!-- 省份选择 -->
        <div class="provinces">
            <div class="province" v-for="(item,index) in provinces.results" :key="index" 
                :class="{'disabled': !item.is_active,'choose':index == provinceChoose}" 
                @mouseover="hovered = true" @mouseleave="hovered = false" @click="getSites(item)"
            > 
                {{ item.name }}
            </div>
        </div>
        <!-- 城市导航栏 -->
        <!-- <div class="cities"> -->
            <!-- <el-anchor> -->
                <!-- 遍历城市中的站点并显示 -->
                <!-- <div v-for="city in cities" :key="city.id"> -->
                    <!-- <el-anchor-link :href="`#${city.id}`"> -->
                        <!-- {{ city.name }} -->
                    <!-- </el-anchor-link> -->
                <!-- </div> -->
            <!-- </el-anchor> -->
        <!-- </div> -->
        <!-- 站点展示 -->
        <div class="mapContainer">
            <Map ref="mapComp"/>
        </div>
        
        <Footer style="width: 100%; position: fixed; bottom: -10px;" />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import axios from 'axios';
import Map from '@/components/Map.vue';
export default {
    components: {
        Header,
        Footer,
        Map
    },
    data() {
        return {
            provinces: [],
            hovered: false,
            provinceChoose:0,
            center:{ lat: 9.839414698367111, lng: -83.94532845465129 } // 默认位置 (哥斯达黎加)

        }
    },
    created() {
        this.getProvinces();  // 在组件创建时获取省份数据
    },
    methods:{
        // 异步获取省份数据
        async getProvinces() {
        try {
            const response = await fetch('/api/provinces');  // 假设你有一个 API 来获取省份数据
            const data = await response.json();
            this.provinces = data;

            // 使用 for...of 循环，遍历 provinces.results
            for (let index = 0; index < this.provinces.results.length; index++) {
                const province = this.provinces.results[index];
                if (province.is_active) {
                    this.provinceChoose = index;  // 找到第一个 is_active 为 true 的省份
                    this.getSites(province);  // 调用 getCities 获取该省份的城市信息
                    break;  // 找到后退出循环
                }
            }
        } catch (error) {
            console.error("Error fetching provinces:", error);
        }
        },
        getSites(item) {
            if(item.is_active){
                this.provinceChoose = item.id-1
                this.$refs.mapComp.fetchStores(item.id)
            }
            else{
                return 
            }
        },
    },
    computed: {
      // 通过计算属性获取当前路由是否是首页（/）
      isHomePage() {
        return this.$route.path === '/';
      }
    }
}
</script>

<style lang="scss" scoped>
.provinces{
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* 允许换行 */
    gap: 40px; /* 设置元素之间的间隔， 可选 */
    margin-top: 20px;
    .province{
        font-size: 20px;
        text-align: center;
        padding: 5px 20px;
        cursor: pointer;
        font-weight: bold;
    }
    .province:hover{
        border-radius: 20px;
        background-color: #dc2626;
        color: white;
        border-bottom: none;
    }
    .province.disabled:hover {
        background-color: #d3d3d3; /* 禁用时鼠标悬浮背景不变 */
        border-radius: 20px;
        color: white;
        cursor: not-allowed; /* 禁用鼠标悬浮 */
    }
    .province.choose{
        border-bottom: 3px solid #dc2626;
    }
}
.mapContainer{
    margin-top: 50px;
    padding: 0 50px;
}

</style>