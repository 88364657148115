<template>
<!-- 顶部组件 -->
<view id="header" class="top-container">
  <!-- 顶部运费说明框 -->
  <!-- <div class="bg-red-600 h-8 font-extrabold text-lg tracking-wider flex items-center justify-center" style="width: 100%;color: white;" v-if="language === 0">
    Entrega gratuita para pedidos superiores a <span class="text-yellow-300">&nbsp;20 cajas</span>
  </div>
  <div class="bg-red-600 h-8 font-extrabold text-lg tracking-wider flex items-center justify-center" style="width: 100%;color: white;" v-else>
    订单箱数达到<span class="text-yellow-300">20箱</span>可免运费 
  </div> -->
  <!-- 顶部栏 -->
  <el-row :gutter="20" class="top">
    <el-col :span="12" >
      <!-- logo框 -->
      <div style="padding: 10px 30px;">
        <a href="/">
          <img src="../assets/images/logo.jpeg" alt="">
        </a>
      </div>
    </el-col>
    <el-col :span="12">
      <!-- 语言切换框 -->
      <div class="flex" style="justify-content: flex-end;align-items: center;padding-right: 20px;">
        <div class="language" :class="{'language_select':language === 0}" @click="changeLanguage(0)">ESPAÑOL</div>
        <el-divider direction="vertical" />
        <div class="language" :class="{'language_select':language === 1}" @click="changeLanguage(1)">简体中文</div>
      </div>
    </el-col>
  </el-row>
  <!-- 菜单栏 -->
  <div class="menu" v-if="isHomePage">
    <div v-for="item in catagory.results" :key="item" class="h-8 catagory_box" >
      <div class="box_content" @click="changeCategory(item.id)">
        {{language === 0 ? item.Category_name :item.cn}}
      </div>
    </div>
  </div>
  <!-- 搜索框 -->
    <!-- <div style="margin: auto;">
      <el-input v-model="searchTxt" placeholder="buscar en toda la tienda aquí..." style="width: 400px;">
        <template #append>
          <el-button :icon="Search" style="background-color: #dc2626; color: white;"/>
        </template>
      </el-input>
    </div> -->
  <!-- 电话 -->
    <!-- <div style="margin-right: 50px;" class="flex items-center">
      <span class="icon-[bxl--whatsapp] text-6xl text-gray-500"></span>
      <div class="text-lg text-gray-500">
        <div class="text-sm">
          Más Informaciones
        </div>
        <div class="text-black font-sans">
          8456-7880
        </div>
      </div>
    </div> -->
  <!-- <el-divider></el-divider> -->
</view>
</template>

<script setup>
import axios from 'axios';
</script>

<script>

export default {
    name:'Header', // 该组件的名字
    data:function(){
      return{
        searchTxt:'',
        catagory:'',
        language:0 //0:ESPAÑOL  1:简体中文
      }
    },
    mounted(){
      this.get_category_info()
    },
    methods:{
      get_category_info(){
        axios.get('/api/category')
             .then(response=>(this.catagory = response.data))
             .catch(error =>{console.log(error)})
        // 获取语言参数
        this.language = Number(this.$route.query.language)
        if(!this.language){
          this.language = 0
        }
      },
      changeLanguage(index){
        //index的值：0:ESPAÑOL  1:简体中文
        let query = {language:index}
        // 1. 获取页面参数
        const page =Number(this.$route.query.page);
        if(page){
          query.page=page
        }
        // 2. 获取类型参数
        const category_id = this.$route.query.category_id
        if(category_id){
          query.category_id=category_id
        }
        // 3. 获取筛选参数
        const status = Number(this.$route.query.status)
        if(status){
          query.status = status
        }
        // 4. 获取搜索参数
        const code = this.$route.query.code
        if(code){
          query.code = code
        }
        this.$router.push({
          query
        })
      },
      changeCategory(index){
        this.$router.push({
          query:{language:this.language,category_id:index}
        })

      }
    },
    watch:{
      //监听路由的变化
      $route(){
        this.language = Number(this.$route.query.language)
      }
    },
    computed: {
      // 通过计算属性获取当前路由是否是首页（/）
      isHomePage() {
        return this.$route.path === '/';
      }
    }
}
</script>

<style lang="scss" scoped>
.top{
  align-items: center;
  .language{
    color: #878484;
    cursor: pointer;
  }
  .language:hover{
    color: #dc2626;
    cursor: pointer;
  }
  .language_select{
    color: #dc2626;
  }
}
.menu{
  display: flex;
  flex-flow: row wrap;
  padding: 0 50px;
  font-size: 16px;
  justify-content: center;
  border-bottom: 1px solid #d4d4d4;
  position: relative;
  .catagory_box{
    // border-top: 3px solid white;
    padding: 2px 20px;
    margin: 0 20px;
    cursor: pointer;
    .box_content{
      display: inline-block;
      position: relative;
      padding: 0 20px;
    }
    &:hover{
      color: #dc2626;
    }
    .box_content::after{
      content:'';
      position: absolute;
      width: 0;
      height: 3px;
      bottom: -5px;
      left: 0;
      background-color: #dc2626;
      transition: width 0.25s ease-out;
    }
    .box_content:hover::after{
      width: 100%;
    }
  }
}
.top_box{
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 5px;
  position: relative;
  .catagory_box{
    // border-top: 3px solid white;
    padding: 2px 20px;
    margin: 0 20px;
    cursor: pointer;
    .box_content{
      display: inline-block;
      position: relative;
      padding: 0 20px;
    }
    &:hover{
      color: #dc2626;
    }
    .box_content::after{
      content:'';
      position: absolute;
      width: 0;
      height: 3px;
      bottom: -5px;
      left: 0;
      background-color: #dc2626;
      transition: width 0.25s ease-out;
    }
    .box_content:hover::after{
      width: 100%;
    }
  }
  
}

</style>