<template>
    <div>
        <!-- 显示地图的容器 -->
        <el-row :gutter="20">
            <el-col :span="8">
                <div v-for="store in stores" :key="store.name" @click="resetCenter(store)" class="storeBox">
                    <el-icon color="#dc2626" :size="40"><LocationFilled /></el-icon>
                    <div class="store">
                        <div class="title">{{ store.name }}</div>
                        <div class="address">{{ store.address }}</div>
                    </div>
                </div>
            </el-col>
            <el-col :span="16">
                <div id="map" style="width: 100%; height: 500px;"></div>
            </el-col>
        </el-row>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name: "Map",
    data() {
      return {
        ProvinceId:0,
        map: null, // Google Maps 地图实例
        stores: [], // 地点信息列表
        markers: [], // 标记实例数组
        lat: null, // 地图中心纬度
        lng: null, // 地图中心经度
      };
    },
    methods: {

        // 获取地点信息
        async fetchStores(index) {
            try {
                const response = await axios.get(`/api/provinces/${index}/sites/`);
                this.stores = response.data; 
                console.log(this.stores)
                // 设置 lat 和 lng
                if (this.stores.length > 0) {
                    this.lat = this.stores[0].latitude;
                    this.lng = this.stores[0].longitude;
                }
                this.initMap(); // 初始化地图
                this.setMarker(this.stores[0]); // 设置初始标记
            } catch (error) {
                console.error('Error fetching stores:', error);
            }
        },
        // 初始化地图
        initMap() {
            console.log(this.lat)
            this.map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: this.lat, lng: this.lng },
            zoom: 15,
            maxZoom: 20,
            minZoom: 3,
            streetViewControl: false,
            mapTypeControl: false,
            });
        },
        // 设置标记
        setMarker(item) {
            const position = item
            ? { lat: item.latitude, lng: item.longitude }
            : { lat: this.lat, lng: this.longitude };
    
            const marker = new google.maps.Marker({
            position: { ...position },
            map: this.map,
            });
    
            this.markers.push(marker);
        },
        // 清除旧标记
        deleteMarker() {
            this.markers.forEach((marker) => marker.setMap(null));
            this.markers = [];
        },
        // 重置地图中心点
        resetCenter(item) {
            this.deleteMarker();
            this.map.panTo({ lat: item.latitude, lng: item.longitude });
            this.setMarker(item);
        },
    },
  };
  </script>
  
  <style lang=scss scope>
  #map {
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .storeBox {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    .store {
        margin-left: 10px;
        .title {
            font-weight: bold;
            font-size: 20px;
            }

            .address {
            font-size: 14px;
            color: #666;
        }
    }
}

.storeBox:hover {
  background-color: #eaeaea;
}




  
  </style>