import { createApp } from 'vue'
import App from './App.vue' // 导入根组件
import router from './router' // 导入路由
import store from './store' // 导入状态管理仓库
import './assets/css/tailwind.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import { GoogleMap,Marker } from 'vue3-google-map'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


// 创建应用实例
const app = createApp(App)

// // 在全局注册 `GoogleMap` 组件
// app.component('GoogleMap', GoogleMap)
// app.component('Marker', Marker)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
  

app.use(store).use(router).use(ElementPlus).mount('#app');


// // 全局注册icon
// const app = createApp(App);
// for (const [key,component] of Object.entries(ElementPlusIconsVue)){
//     app.component(key,component);
// }

// app.use(store).use(router).use(ElementPlus).mount('#app')